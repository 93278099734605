import React from 'react';
import Layout from "../components/layout"
import Svg from "../components/svg"
import SEO from "../components/seo"
import A80 from "../animations/a80"
import A160 from "../animations/a160"
import A400 from "../animations/a400"

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
    const data = content.data;
    return (
        <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
            <SEO description={data.seo_description} title={data.seo_title} image={data.seo_image} />
            <div className="layer-top layer-text">
                <A160 className="privacy-liquid-1" />
                <A400 className="privacy-liquid-2" />
                <A80 className="privacy-liquid-3" />
                <div className="small-wrap">
                    <h1
                        data-sal="slide-left"
                        data-sal-duration="1000"
                        dangerouslySetInnerHTML={{__html: data.title }} />
                    <div
                        data-sal="fade"
                        data-sal-duration="1000"
                        className="layer-label" dangerouslySetInnerHTML={{__html: data.section }} />
                    {data.blocks.map((block, index)=>{
                        return (
                            <div key={index} className="group group-3">
                                <div
                                    data-sal="slide-right"
                                    data-sal-duration="1000"
                                    className="left">
                                    <div className="h3" dangerouslySetInnerHTML={{__html: block.title }} />
                                </div>
                                <div
                                    data-sal="slide-left"
                                    data-sal-duration="1000"
                                    className="right" dangerouslySetInnerHTML={{__html: block.text }} />
                            </div>
                        )
                    })}
                    <div
                        data-sal="slide-up"
                        data-sal-duration="1000"
                        className="swipe desktop-hide">
                        <Svg name="swipe" />
                        {data.slide_text}
                    </div>
                    <div
                        data-sal="slide-up"
                        data-sal-duration="1000"
                        className="table-wrap">
                        <div className="table-scroll" dangerouslySetInnerHTML={{__html: data.table }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
